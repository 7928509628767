import { addHistoryData } from './utils/searchHistoryLocalStorage.js'
import { htmlDecode, getQueryString } from '@shein/common-function'
import { getStoreCodeFromUrl } from 'public/src/pages/store_pages/js/utils.js'

function syncHisData({ keywords, afterTreatmentKeywords, empty, pageType = 'all', localCacheWordKey = '', word_id }) {

  gbCommonInfo.keywords = keywords

  // 空值不存入缓存
  if (!empty) {
    const _afterTreatmentKeywords = afterTreatmentKeywords ?? keywords
    keywords && addHistoryData({
      keywords: _afterTreatmentKeywords, 
      pageType, 
      key: localCacheWordKey, 
      word_id
    })

    appEventCenter.$emit('syncSearchState')
  } else {
    appEventCenter.$emit('syncSearchState', { empty })
  }
}

function initSearchPageFirstLoadEvent() {
  appEventCenter.$on('searchPageFirstLoad', ({ keywords: searchWords, origin_total, pageType = 'all', localCacheWordKey, word_id = 0 }) => {
    const keywords = decodeURIComponent(searchWords)
    if (origin_total ) {
      const search_id = getQueryString({ key: 'search_id' }) || undefined
      const search_cat = getQueryString({ key: 'search_cat' }) || undefined

      let afterTreatmentKeywords = search_id ? { keywords, guideObj: { search_id, search_cat } } : keywords

      syncHisData({ keywords, afterTreatmentKeywords, pageType, localCacheWordKey, word_id })
    } else {
      syncHisData({ keywords, empty: true, pageType, localCacheWordKey, word_id })
    }
  })
}

function initSearchRedirectEvent() {
  // search redir spa
  appEventCenter.$on('searchRedirect', ({ searchWords, pageType, localCacheWordKey, word_id }) => {
    if (!searchWords) return

    const searchRedirectWords = htmlDecode({ text: searchWords })
    searchRedirectWords && syncHisData({ keywords: searchRedirectWords, pageType, localCacheWordKey, word_id })
  })
}

function cacheSearchRedirectWords(){
  // search redir landing
  const searchRedirectWords = getQueryString({ key: 'search_redir' }) && getQueryString({ key: 'search_words' })
  const pageType = getStoreCodeFromUrl() ? 'store' : 'all'
  searchRedirectWords && syncHisData({ 
    keywords: searchRedirectWords, pageType, 
    word_id: getQueryString({ key: 'word_id' }) || 0 })
}

export {
  initSearchPageFirstLoadEvent,
  initSearchRedirectEvent,
  cacheSearchRedirectWords,
}
