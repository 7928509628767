// 泛列表点刷策略
import { mapMutations } from 'vuex'
import { storeModuleName as pl2ModuleName } from '../../product_app/store/modules/product_list_v2/index'
import { setFeedbackRecInfo } from '../js/utils'
import { computePageLimit } from 'public/src/pages/common/refresh_list_strategy/helpers/index.js'
import { getListPageInfo } from 'public/src/pages/product_list_v2/js/utils'
import { removeExposedProducts, getExposedGoods } from 'public/src/pages/common/refresh_list_strategy/helpers/index.js'

export default ({
  storeModuleName = pl2ModuleName,
}) => ({
  beforeRouteEnter(to, from, next) {
    if (typeof window === 'undefined') return next()
    next(async vm => {
      const isBackFromDetail = setFeedbackRecInfo({ productListInstance: vm.$refs.ProductList, path: from.path })
      if (isBackFromDetail) {
        const requestEarlyBack = vm.getClickRefreshAbt('request_early_back')
        if (requestEarlyBack) {
          // 点刷逻辑request_early_back 先删除
          vm.removeUnexposedGoods()
          // 点刷逻辑request_early_back 点刷请求
          await vm.triggerComeBackRefreshList()
          // 点推逻辑执行
          await vm.$nextTick(() => {
            vm.addRecommendProductHandle && vm.addRecommendProductHandle(isBackFromDetail)
          })
          // 点刷逻辑request_early_back 点刷插入
          vm.resolveDelayResolveNewData()
        } else {

          // 点刷逻辑request_early 点刷商品插入
          if (vm.getClickRefreshAbt('request_early')) {
            if (vm.getRefreshListPromise) {
              await vm.getRefreshListPromise
            }
            // 删除未曝光商品
            vm.removeUnexposedGoods()
          }

          // 点后推原本逻辑
          await vm.$nextTick(() => {
            vm.addRecommendProductHandle && vm.addRecommendProductHandle(isBackFromDetail)
          })

          // 点刷逻辑request_early 点刷商品插入
          if (vm.getClickRefreshAbt('request_early')) {
            vm.resolveDelayResolveNewData()
          }
        }
      }
    })
  },
  data() {
    return {
      delayResolveNewData: null
    }
  },
  methods: {
    ...mapMutations(storeModuleName, [
      'removeUnexposedGoods',
      'updateExposedGoods',
      'updateExposedGoodsWithSpu',
      'resetExposeGoods',
    ]),
    // 获取已经曝光的商品(点刷+分页+点推)
    getHasExposedGoods() {
      return this.Results?.exposedGoods || []
    },
    // 获取已经曝光的商品(点刷+分页)
    getHasExposedGoodsWithoutRecommend() {
      return this.Results?.exposedGoodsWithSpu || []
    },
    isOverMaxExposedGoods() {
      const MAX_EXPOSED_GOODS = 240
      if (this.getHasExposedGoodsWithoutRecommend().length < MAX_EXPOSED_GOODS) {
        return false
      }
      return true
    },
    handleExposedCard({ goodsId, spuId, divideTime }) {
      // 已经曝光过的商品
      if (this.getHasExposedGoods().find(item => item.goods_id == goodsId)) return

      this.updateExposedGoods({ goodsId, divideTime })
      this.updateExposedGoodsWithSpu({ goodsId, spuId, divideTime })
    },
    getComputePageLimit() {
      return new Promise(resolve => {
        const { page, limit } = computePageLimit(this.getHasExposedGoodsWithoutRecommend())

        let divideTime = ''
        if (this.getClickRefreshAbt('request_early_back')) {
          divideTime = 'request_early_back' 
        }
        if (this.getClickRefreshAbt('request_early')) {
          divideTime = 'request_early' 
        }
        // 店铺场景特殊处理
        const { query = {}, meta = {} } = this.$route
        const { isStorePage } = getListPageInfo({ search_type: query.search_type, type: meta.type })
        if (isStorePage) {
          this.loadMore({
            page,
            limit,
            divideTime,
            isClickRefresh: 1,
          })
          resolve()
          return
        }
        this.Request.query.page = page
        this.Request.query.limit = limit
        this.Request.query.isClickRefresh = 1
        this.Request.query.divideTime = divideTime
        this.Request.query.withoutAbort = true

        const fetchDataParams = { requestType: 'nextpage', from: 'loadMore', delayResolveData: true }

        this.fetchData(fetchDataParams).then(newData => {
          this.Request.type = ''
          // 存储数据，不做 resolveData 处理
          this.delayResolveNewData = newData 

          this.updateSearchOptimizeReqFlag && this.updateSearchOptimizeReqFlag(false)
          this.Request.query.isClickRefresh = 0
          this.Request.query.divideTime = ''

          // 数据处理结束
          resolve()
        })
      })
    },

    async triggerComeBackRefreshList() {
      // 超过最大曝光商品
      if (this.isOverMaxExposedGoods()) return

      await this.getComputePageLimit()
    },
    // abt
    // 主搜场景使用 PageDivideTime
    // 泛列表使用 ListDivideTime
    // 店铺使用 StoreDivideTime
    // 点刷方案一: 返回时触发 request_early_back
    // 点刷方案二: 点击时触发 request_early
    getClickRefreshAbt(type = 'request_early_back') {
      // 超过最大曝光商品
      if (this.isOverMaxExposedGoods()) return false

      const { query = {}, meta = {} } = this.$route
      const {
        isEntityListPage,
        isSheinPicks,
        isAllSearchType, // 根据产品要求，所有搜索场景都要 
        isSelectListPage,
        isStorePage,
      } = getListPageInfo({ search_type: query.search_type, type: meta.type })

      const isListPage = [isEntityListPage, isSheinPicks, isSelectListPage].includes(true)
      const { PageDivideTime, ListDivideTime, StoreDivideTime } = this.listAbtResult
      const listDivideTimeAbt = ListDivideTime?.p?.ListDivideTime
      const searchDivideTimeAbt = PageDivideTime?.p?.page_divide_time
      // const listDivideTimeAbt = 'request_early' 
      // const searchDivideTimeAbt = 'request_early'
      
      // 泛列表
      if (isListPage) {
        return listDivideTimeAbt === type 
      }
      // 搜索
      if (isAllSearchType) {
        return searchDivideTimeAbt === type
      }
      // 店铺
      if (isStorePage) {
        return StoreDivideTime?.p?.StoreDivideTime === type
      }
    },
    isSheinPicks() {
      const { query = {}, meta = {} } = this.$route
      const {
        isSheinPicks,
      } = getListPageInfo({ search_type: query.search_type, type: meta.type })
      return isSheinPicks
    },
    resolveDelayResolveNewData() {
      if (this.delayResolveNewData) {
        // 待插入的刷新商品去重
        // const pureGoods = this.goods.filter(item => item.goods_id && item.type !== 'recommend-for-you')
        // let { exposedProducts } = getExposedGoods({ exposedProducts: this.getHasExposedGoods(), goods: this.goods })
        const products = removeExposedProducts(
          this.delayResolveNewData.bffProductsInfo.products,
          // exposedProducts
          // this.getHasExposedGoods()
          this.goods
        )
        products.forEach(good => {
          good.divideTime = this.getClickRefreshAbt() ? 'request_early_back' : 'request_early'
          good.isRefresh = 1
        })
        if (!products?.length) {
          return
        }
        this.delayResolveNewData.bffProductsInfo.products = products
        this.delayResolveNewData.searchOptimize = true // 加标记，否则就会nomoreData，在mutation处理了
        
        this.Request.type = 'nextpage'
        this.resolveData(this.delayResolveNewData)

        return 
      }

    },
  }
})
