<template>
  <AppCcc
    v-if="content.length > 0"
    :content="content"
    :context="context"
    :scene-data="sceneData"
    :hooks="cccxHooks"
  />
</template>
  
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'StoreRecommendCccx', // 店铺兜底推荐推荐接入cccx
})
</script>
<script setup>
import { ref, computed, onMounted } from 'vue'
import AppCcc from 'public/src/pages/components/ccc/Index.vue'
import schttp from 'public/src/services/schttp'
import { getStoreCodeFromUrl } from 'public/src/pages/store_pages/js/utils.js'

const cccxConfigInfo = ref({})
const cccxLanguage = ref({})

const content = computed(()=>{
  const _content = cccxConfigInfo.value.content || []
  return _content.map((item)=>{
    item.isAppBff = true
    return item
  })
})

const context = computed(()=>{
  return {
    language: cccxLanguage.value,
    forceRenderNumber: 0,
  }
})

const sceneData = computed(()=>{
  const { pageType } = cccxConfigInfo.value
  return {
    pageType,
    sceneName: pageType,
    pageFrom: pageType,
   
  }
})

const cccxHooks = computed(()=>{
  return {
    RECOMMENDFLOW: {
      generateContextParams: ()=>{
        const storeCode = getStoreCodeFromUrl()
        return {
          storeCode,
        }
      },
    }
  }
})

const getBffCccxConfig = async()=>{
  try {
    const resData = await schttp({
      method: 'GET',
      url: '/ccc/common_component',
      useBffApi: true,
      params: {
        cccPageType: 'pageStoreEmpytRecom'
      },
    })
    const { code, info } = resData || {}
    // console.log('=======getBffCccxConfig resData', resData)
    if(code !== '0'){
      throw resData
    }
    return info || {}
  } catch (error) {
    // console.log('=========getBffCccxConfig error', error)
    return {}
  }
}

const getCccxLanguage = async()=>{
  try {
    const resData = await schttp({
      method: 'POST',
      url: '/api/ccc/language/query',
      data: {
        types: ['recomendFlow', 'itemLanguage']
      }
    })
    const { code, data } = resData || {}
    if(code !== 0){
      throw resData
    }
    return data || {}
  } catch (error) {
    return {}
  }
}

const getCccxData = async()=>{
  const [_cccxConfigInfo,  _cccxLanguage] = await Promise.all([getBffCccxConfig(), getCccxLanguage()])
  cccxConfigInfo.value = _cccxConfigInfo
  cccxLanguage.value = _cccxLanguage
}

onMounted(() => {
  getCccxData()
})



</script>
  
