import schttp from 'public/src/services/schttp'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'

// buyBoxPrice: {price: "$3.57"}

// web中间层请求
const webRquset = async ({ products }) => {

  const { list } = await schttp({
    method: 'POST',
    url: '/api/productList/buyBoxPrice/get',
    data: { products }
  })
  
  return list || []
}

// java中间层请求
const bffRquset = async ({ products, sheinClubInfo }) => {
  const res = await schttp({
    useBffApi: true,
    method: 'POST',
    url: '/recommend/buybox/buybox_entrance',
    data: { 
      goodsInfos: products,
      isPaidMember: sheinClubInfo?.isPaid ? 1 : 0,
    }
  })

  const buyBoxEntranceList = res?.info?.buyBoxEntranceList || []
  if (buyBoxEntranceList.length > 0) {
    const product = buyBoxEntranceList.map((item) => {
      return {
        goods_id: Number(item.goodsId),
        buyBoxPrice: {
          price: item.buyBox?.lowestPrice
        }
      }
    }) 
    return product || []
  }
  return []
}

export default {
  methods: {
    async getBuyBoxPrices (goods, sheinClubInfo) {
      const useBffApi = isSwitchBffApiVersion(['selection_v1'])?.selection_v1

      const products = goods
        .filter(item => item.buyBoxCount?.count)
        .map(item => {
          if (useBffApi) {
            return {
              // java中间层用
              goodsId: item.goods_id,
              goodsSn: item.goods_sn,
              mallCode: item.mall_code
            }
          } else {
            // web中间层用
            return {
              goods_id: item.goods_id,
              goods_sn: item.goods_sn,
              mall_code: item.mall_code,
              group_id: item.buyBoxCount.group_id,
            }
          }
        })
      
      if (!products.length) return

      const resProduct = useBffApi ? await bffRquset({ products, sheinClubInfo }) : await webRquset({ products })

      resProduct.forEach(item => {
        const curGoods = this.goods.find(obj => obj.goods_id === item.goods_id)
        if (!curGoods) return
        Vue.set(curGoods, 'buyBoxPrice', item.buyBoxPrice)
      })
    }
  },
}
